dmx.Component('stripe-p24-bank', {

    initialData: {
        complete: false,
        empty: true,
        value: '',
        error: undefined,
        source: undefined,
        paymentMethod: undefined,
        paymentIntent: undefined
    },

    attributes: {
        'value': {
            type: String,
            default: ''
        },

        'hide-icon': {
            type: Boolean,
            default: false
        },

        'disabled': {
            type: Boolean,
            default: false
        },

        'return-url': {
            type: String,
            default: window.location.href
        },

        'hide-errors': {
            type: Boolean,
            default: false
        },

        'accountholder-name': {
            type: String,
            default: ''
        },

        'tos-shown-and-accepted': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        blur: function() {
            if (this.element) {
                this.element.blur();
            }
        },

        clear: function() {
            if (this.element) {
                this.element.clear();
            }
        },

        focus: function() {
            if (this.element) {
                this.element.focus();
            }
        },

        createSource: function(data) {
            if (this.element) {
                var self = this;
                return dmx.stripe.instance.createSource(this.element, data).then(function(result) {
                    if (result.error) {
                        self.set('error', result.error);
                        self.dispatchEvent('error');
                    } else {
                        self.set('source', result.source);
                        self.dispatchEvent('source_created');
                    }
                    return result;
                });
            }
        },

        createPaymentMethod: function(data) {
            if (this.element) {
                var self = this;
                return dmx.stripe.instance.createPaymentMethod({
                    type: 'p24',
                    p24: this.element
                }).then(function(result) {
                    if (result.error) {
                        self.set('error', result.error);
                        self.dispatchEvent('error');
                    } else {
                        self.set('paymentMethod', result.paymentMethod);
                        self.dispatchEvent('payment_method_created');
                    }
                    return result;
                });
            }
        },

        confirmPayment: function(clientSecret) {
            var self = this;
            if (this.element) {
                dmx.stripe.instance.confirmP24Payment(clientSecret || dmx.stripe.client_secret, {
                    payment_method: {
                        p24: this.element,
                        billing_details: {
                            name: this.props['accountholder-name']
                        }
                    },
                    payment_method_options: {
                        p24: {
                            tos_shown_and_accepted: this.props['tos-shown-and-accepted']
                        }
                    },
                    return_url: this.props['return-url']
                }).then(function(result) {
                    if (result.error) self.set('error', result.error);
                    self.set('paymentIntent', result.paymentIntent);
                });
            }
        }
    },

    events: {
        change: Event,
        ready: Event,
        focus: Event,
        blur: Event,
        escape: Event,
        error: Event,
        source_created: Event,
        payment_method_created: Event,
        payment_confirmed: Event
    },

    onchange: function(event) {
        this.set(event);

        if (!this.props['hide-errors']) {
            this.errorElement.textContent = event.error ? event.error.message : '';
        }
    },

    render: function(node) {
        var self = this;
        dmx.stripe.ready(function(stripe) {
            var theme = dmx.stripe.theme[window.bootstrap ? 'bootstrap' : 'default'];

            self.element = dmx.stripe.elements.create('p24Bank', {
                value: self.props['value'],
                hideIcon: self.props['hide-icon'],
                disabled: self.props['disabled'],
                classes: theme.classes,
                style: theme.style
            });

            self.element.mount(node);

            self.errorElement = document.createElement('div');
            self.errorElement.className = theme.error.class;

            node.insertAdjacentElement('afterend', self.errorElement);

            self.element.on('change', self.onchange.bind(self));
            self.element.on('change', self.dispatchEvent.bind(self, 'change'));
            self.element.on('ready', self.dispatchEvent.bind(self, 'ready'));
            self.element.on('focus', self.dispatchEvent.bind(self, 'focus'));
            self.element.on('blur', self.dispatchEvent.bind(self, 'blur'));
            self.element.on('escape', self.dispatchEvent.bind(self, 'escape'));
        });
    },

    update: function(props) {
        if (this.element) {
            if (this.props['value'] != props['value']) {
                this.element.update({ value: this.props['value'] });
            }

            if (this.props['hide-icon'] != props['hide-icon']) {
                this.element.update({ hideIcon: this.props['hide-icon'] });
            }

            if (this.props['disabled'] != props['disabled']) {
                this.element.update({ disabled: this.props['disabled'] });
            }
        }
    },

    destroy: function() {
        if (this.element) {
            this.element.destroy();
        }
    }

});