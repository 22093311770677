dmx.Component('stripe-card-expiry', {

    initialData: {
        complete: false,
        empty: true,
        error: undefined
    },

    attributes: {
        'placeholder': {
            type: String,
            default: ''
        },

        'disabled': {
            type: Boolean,
            default: false
        },

        'hide-errors': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        blur: function() {
            this.element.blur();
        },

        clear: function() {
            this.element.clear();
        },

        focus: function() {
            this.element.focus();
        }
    },

    events: {
        change: Event,
        ready: Event,
        focus: Event,
        blur: Event,
        escape: Event
    },

    onchange: function(event) {
        this.set(event);

        if (!this.props['hide-errors']) {
            this.errorElement.textContent = event.error ? event.error.message : '';
        }
    },

    render: function(node) {
        var self = this;
        dmx.stripe.ready(function(stripe) {
            var theme = dmx.stripe.theme[window.bootstrap ? 'bootstrap' : 'default'];

            self.element = dmx.stripe.elements.create('cardExpiry', {
                placeholder: self.props['placeholder'],
                disabled: self.props['disabled'],
                classes: theme.classes,
                style: theme.style
            });

            self.element.mount(node);

            self.errorElement = document.createElement('div');
            self.errorElement.className = theme.error.class;

            node.insertAdjacentElement('afterend', self.errorElement);

            self.element.on('change', self.onchange.bind(self));
            self.element.on('change', self.dispatchEvent.bind(self, 'change'));
            self.element.on('ready', self.dispatchEvent.bind(self, 'ready'));
            self.element.on('focus', self.dispatchEvent.bind(self, 'focus'));
            self.element.on('blur', self.dispatchEvent.bind(self, 'blur'));
            self.element.on('escape', self.dispatchEvent.bind(self, 'escape'));
        });
    },

    update: function(props) {
        if (this.props['placeholder'] != props['placeholder']) {
            this.element.update({ placeholder: this.props['placeholder'] });
        }

        if (this.props['disabled'] != props['disabled']) {
            this.element.update({ disabled: this.props['disabled'] });
        }
    },

    destroy: function() {
        this.element.destroy();
    }

});