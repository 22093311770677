dmx.Component('stripe-card-number', {

    initialData: {
        complete: false,
        brand: '',
        empty: true,
        error: undefined,
        paymentMethod: undefined,
        paymentIntent: undefined,
        setupIntent: undefined
    },

    attributes: {
        'placeholder': {
            type: String,
            default: ''
        },

        'icon-style': {
            type: String,
            default: 'default' // default or solid
        },

        'show-icon': {
            type: Boolean,
            default: false
        },

        'disabled': {
            type: Boolean,
            default: false
        },

        'hide-errors': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        blur: function() {
            this.element.blur();
        },

        clear: function() {
            this.element.clear();
        },

        focus: function() {
            this.element.focus();
        },

        /**
         * Convert information collected by the card element into a PaymentMethod object that you safely pass to your server to use in an API call.
         * @returns {Promise<StripePaymentMethodResponse>}
         */
         createPaymentMethod: function() {
            if (this.element) {
                var self = this;
                return dmx.stripe.instance.createPaymentMethod({
                    type: 'card',
                    card: this.element
                }).then(function(result) {
                    if (result.error) {
                        self.set('error', result.error);
                        self.dispatchEvent('error');
                    } else {
                        self.set('paymentMethod', result.paymentMethod);
                        self.dispatchEvent('payment_method_created');
                    }
                    return result;
                });
            }
        },

        /**
         * When called, it will confirm the PaymentIntent with data you provide and carry out 3DS or other next actions if they are required.
         * 
         * If you are using Dynamic 3D Secure, stripe.confirmCardPayment will trigger your Radar rules to execute and may open a dialog for your
         * customer to authenticate their payment.
         * 
         * When you confirm a PaymentIntent, it needs to have an attached PaymentMethod. In addition to
         * confirming the PaymentIntent, this method can automatically create and attach a new PaymentMethod for you. It can also be called with
         * an existing PaymentMethod, or if you have already attached a PaymentMethod you can call this method without needing to provide any
         * additional data. These use cases are detailed in the sections that follow.
         * @param {string} clientSecret
         * @returns {Promise<StripePaymentIntentResponse>}
         */
        confirmPayment: function(clientSecret) {
            if (this.element) {
                var self = this;
                return dmx.stripe.instance.confirmCardPayment(clientSecret, {
                    payment_method: { card: this.element }
                }).then(function(result) {
                    if (result.error) {
                        self.set('error', result.error);
                        self.dispatchEvent('error');
                    } else {
                        self.set('paymentIntent', result.paymentIntent);
                        self.dispatchEvent('payment_confirmed');
                    }
                    return result;
                });
            }
        },

        /**
         * When called, it will confirm the SetupIntent with data you provide and carry out 3DS or other next actions if they are required.
         * When you confirm a SetupIntent, it needs to have an attached PaymentMethod. In addition to confirming the SetupIntent, this method
         * can automatically create and attach a new PaymentMethod for you. It can also be called with an existing PaymentMethod, or if you 
         * have already attached a PaymentMethod you can call this method without needing to provide any additional data. These use cases are 
         * detailed in the sections that follow.
         * @param {string} clientSecret
         * @returns {Promise<StripeSetupIntentResponse>}
         */
        confirmSetup: function(clientSecret) {
            if (this.element) {
                var self = this;
                return dmx.stripe.instance.confirmCardSetup(clientSecret, {
                    payment_method: { card: this.element }
                }).then(function(result) {
                    if (result.error) {
                        self.set('error', result.error);
                        self.dispatchEvent('error');
                    } else {
                        self.set('setupIntent', result.setupIntent);
                        self.dispatchEvent('setup_confirmed');
                    }
                    return result;
                });
            }
        }
    },

    events: {
        change: Event,
        ready: Event,
        focus: Event,
        blur: Event,
        escape: Event
    },

    onchange: function(event) {
        this.set(event);

        if (!this.props['hide-errors']) {
            this.errorElement.textContent = event.error ? event.error.message : '';
        }
    },

    render: function(node) {
        var self = this;
        dmx.stripe.ready(function(stripe) {
            var theme = dmx.stripe.theme[window.bootstrap ? 'bootstrap' : 'default'];

            self.element = dmx.stripe.elements.create('cardNumber', {
                placeholder: self.props['placeholder'],
                iconStyle: self.props['icon-style'],
                showIcon: self.props['show-icon'],
                disabled: self.props['disabled'],
                classes: theme.classes,
                style: theme.style
            });

            self.element.mount(node);

            self.errorElement = document.createElement('div');
            self.errorElement.className = theme.error.class;

            node.insertAdjacentElement('afterend', self.errorElement);

            self.element.on('change', self.onchange.bind(self));
            self.element.on('change', self.dispatchEvent.bind(self, 'change'));
            self.element.on('ready', self.dispatchEvent.bind(self, 'ready'));
            self.element.on('focus', self.dispatchEvent.bind(self, 'focus'));
            self.element.on('blur', self.dispatchEvent.bind(self, 'blur'));
            self.element.on('escape', self.dispatchEvent.bind(self, 'escape'));
        });
    },

    update: function(props) {
        if (this.props['placeholder'] != props['placeholder']) {
            this.element.update({ placeholder: this.props['placeholder'] });
        }

        if (this.props['icon-style'] != props['icon-style']) {
            this.element.update({ iconStyle: this.props['icon-style'] });
        }

        if (this.props['show-icon'] != props['show-icon']) {
            this.element.update({ showIcon: this.props['show-icon'] });
        }

        if (this.props['disabled'] != props['disabled']) {
            this.element.update({ disabled: this.props['disabled'] });
        }
    },

    destroy: function() {
        this.element.destroy();
    }

});