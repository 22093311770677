/**
 * Afterpay or Clearpay Site Messaging
 * https://stripe.com/docs/payments/afterpay-clearpay/site-messaging
 */

dmx.Component('stripe-afterpay-message', {

    attributes: {
        'amount': {
            type: Number,
            default: null,
            required: true
        },

        'currency': {
            type: String,
            default: 'USD',
            enum: ['USD', 'CAD', 'AUD', 'NZD', 'GBP']
        },

        'logo-type': {
            type: String,
            default: 'badge',
            enum: ['badge', 'lockup']
        },

        'badge-theme': {
            type: String,
            default: 'black-on-mint',
            enum: ['black-on-mint', 'black-on-white', 'mint-on-black', 'white-on-black']
        },

        'lockup-theme': {
            type: String,
            default: 'black',
            enum: ['black', 'white', 'mint']
        },

        'modal-theme': {
            type: String,
            default: 'mint',
            enum: ['mint', 'white']
        },

        'intro-text': {
            type: String,
            default: 'In',
            enum: ['In', 'in', 'Or', 'or', 'Pay', 'pay', 'Pay in', 'pay in']
        },

        'modal-link-style': {
            type: String,
            default: 'circled-info-icon',
            enum: ['circled-info-icon', 'learn-more-text', 'more-info-text']
        },

        'is-not-eligible': {
            type: Boolean,
            default: false
        },

        'is-cart-not-eligible': {
            type: Boolean,
            default: false
        },

        'hide-interest-free': {
            type: Boolean,
            default: false
        },

        'hide-with': {
            type: Boolean,
            default: false
        },

        'max': {
            type: Number,
            default: undefined
        },

        'min': {
            type: Number,
            default: undefined
        },

        'hide-lower-limit': {
            type: Boolean,
            default: false
        },

        'hide-upper-limit': {
            type: Boolean,
            default: false
        },

        'amount-is-float': {
            type: Boolean,
            default: false
        }
    },

    render: function(node) {
        dmx.stripe.ready(function() {
            this.element = dmx.stripe.elements.create('afterpayClearpayMessage', {
                amount: (this.props['amount-is-float'] ? 100 : 1) * this.props['amount'],
                currency: this.props['currency'],
                logoType: this.props['logo-type'],
                badgeTheme: this.props['badge-theme'],
                lockupTheme: this.props['lockup-theme'],
                modalTheme: this.props['modal-theme'],
                introText: this.props['intro-text'],
                modalLinkStyle: this.props['modal-link-style'],
                isEligible: !this.props['is-not-eligible'],
                isCartEligible: !this.props['is-cart-not-eligible'],
                showInterestFree: !this.props['hide-interest-free'],
                showWith: this.props['hide-with'],
                max: this.props['max'],
                min: this.props['min'],
                showLowerLimit: !this.props['hide-lower-limit'],
                showUpperLimit: !this.props['hide-upper-limit']
            });
            this.element.mount(node);
        }.bind(this));
    },

    destroy: function() {
        if (this.element) {
            this.element.destroy();
        }
    }

});