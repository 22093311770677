dmx.Component('stripe-payment-request-button', {

    initialData: {
        token: null,
        source: null,
        paymentMethod: null,
        shippingAddress: null,
        shippingOption: null
    },

    attributes: {
        'country': {
            type: String,
            default: ''
        },

        'currency': {
            type: String,
            default: ''
        },

        'label': {
            type: String,
            default: ''
        },

        'amount': {
            type: Number,
            default: null
        },

        'pending': {
            type: Boolean,
            default: false
        },

        'items': {
            type: Array,
            default: null
        },

        'request-name': {
            type: Boolean,
            default: false
        },

        'request-email': {
            type: Boolean,
            default: false
        },

        'request-phone': {
            type: Boolean,
            default: false
        },

        'request-shipping': {
            type: Boolean,
            default: false
        },

        'shipping-options': {
            type: Array,
            default: null
        }
    },

    methods: {
        complete: function() {
            if (this.complete) {
                this.complete()
            }
        },

        updateWith: function(details) {
            if (this.updateWith) {
                this.updateWith(details);
            }
        }
    },

    events: {
        click: Event,
        token: Event,
        source: Event,
        paymentmethod: Event,
        shippingoptionchange: Event
    },

    render: function(node) {
        var self = this;
        dmx.stripe.ready(function(stripe) {
            self.paymentRequest = dmx.stripe.paymentRequest({
                country: self.props.country,
                currency: self.props.currency,
                total: {
                    label: self.props.label,
                    amount: self.props.amount,
                    pending: self.props.pending
                },
                displayItems: self.props.items,
                requestPayerName: self.props['request-name'],
                requestPayerEmail: self.props['request-email'],
                requestPayerPhone: self.props['request-phone'],
                requestShipping: self.props['request-shipping'],
                shippingOptions: self.props['shipping-options']
            });
            self.paymentRequest.on('token', function(event) {
                self.set('token', event.token);
                self.dispatchEvent('token');
                self.complete = event.complete;
            });
            self.paymentRequest.on('source', function(event) {
                self.set('source', event.source);
                self.dispatchEvent('source');
                self.complete = event.complete;
            });
            self.paymentRequest.on('paymentmethod', function(event) {
                self.set('paymentMethod', event.paymentMethod);
                self.dispatchEvent('paymentmethod');
                self.complete = event.complete;
            });
            self.paymentRequest.on('shippingoptionchange', function(event) {
                self.set('shippingOption', event.shippingOption);
                self.dispatchEvent('shippingoptionchange');
                self.updateWith = event.updateWith;
            });
            self.paymentRequest.on('shippingaddresschange', function(event) {
                self.set('shippingAddress', event.shippingAddress);
                self.dispatchEvent('shippingaddresschange');
                self.updateWith = event.updateWith;
            });
            self.paymentRequest.on('cancel', self.dispatchEvent.bind(self, 'cancel'));
            self.element = dmx.stripe.elements.create('paymentRequestButton', {
                paymentRequest: self.paymentRequest
            });
            self.paymentRequest.canMakePayment().then(function(result) {
                if (result) {
                    self.element.mount(node);
                    self.element.on('click', self.dispatchEvent.bind(self, 'click'));
                }
            });
        });
    },

    update: function(props) {
        if (this.paymentRequest) {
            if (!dmx.equal(props, this.props)) {
                this.paymentRequest.update({
                    country: this.props.country,
                    currency: this.props.currency,
                    total: {
                        label: this.props.label,
                        amount: this.props.amount,
                        pending: this.props.pending
                    },
                    displayItems: this.props.items,
                    requestPayerName: this.props['request-name'],
                    requestPayerEmail: this.props['request-email'],
                    requestPayerPhone: this.props['request-phone'],
                    requestShipping: this.props['request-shipping'],
                    shippingOptions: this.props['shipping-options']
                });
            }
        }
    },

    destroy: function() {
        if (this.element) {
            this.element.destroy();
        }
    }

});