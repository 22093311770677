dmx.Component('stripe-payment-method', {

    initialData: {
        /**
         * @property {string} selected - The selected payment method
         */
        selected: 'card'
    },

    attributes: {
        'payment-methods': {
            type: Array,
            default: ['card'],
            enum: ['afterpay_clearpay', 'alipay', 'au_becs_debit', 'bancontact',
                   'card', 'eps', 'fpx', 'giropay', 'grabpay', 'ideal', 'oxxo',
                   'p24', 'sepa_debit', 'sofort']
        },

        'selected': {
            type: String,
            default: 'card',
            enum: ['afterpay_clearpay', 'alipay', 'au_becs_debit', 'bancontact',
                   'card', 'eps', 'fpx', 'giropay', 'grabpay', 'ideal', 'oxxo',
                   'p24', 'sepa_debit', 'sofort']
        },

        'display': {
            type: String,
            default: 'select',
            enum: ['select', 'pills', 'tabs', 'buttons'] // bs5 only
        },

        'hide-postal-code': { // for card element
            type: Boolean,
            default: false
        },

        'icon-style': { // for card, iban, aubank element
            type: String,
            default: 'default',
            enum: ['solid', 'default']
        },

        'hide-icon': { // for card, iban, ideal, p24, eps, aubank element
            type: Boolean,
            default: false
        },

        'hide-errors': {
            type: Boolean,
            default: false
        },

        'account-holder-type': { // for fpx element
            type: String,
            default: 'individual',
            enum: ['company', 'individual']
        },

        // Following are for localization
        'locale-afterpay_clearpay': {
            type: String,
            default: 'Afterpay'
        },

        'locale-alipay': {
            type: String,
            default: 'Alipay'
        },

        'locale-au_becs_debit': {
            type: String,
            default: 'BECS debit'
        },

        'locale-bancontact': {
            type: String,
            default: 'Bancontact'
        },

        'locale-card': {
            type: String,
            default: 'Card'
        },

        'locale-eps': {
            type: String,
            default: 'EPS'
        },

        'locale-fpx': {
            type: String,
            default: 'FPX'
        },

        'locale-giropay': {
            type: String,
            default: 'Giropay'
        },

        'locale-grabpay': {
            type: String,
            default: 'GrabPay'
        },

        'locale-ideal': {
            type: String,
            default: 'iDEAL'
        },

        'locale-oxxo': {
            type: String,
            default: 'OXXO'
        },

        'locale-p24': {
            type: String,
            default: 'Przelewy24'
        },

        'locale-sepa_debit': {
            type: String,
            default: 'SEPA debit'
        },

        'locale-sofort': {
            type: String,
            default: 'Sofort'
        },

        'locale-fpx-conditions': {
            type: String,
            default: 'By Proceding, you agree to FPX’s Terms and Conditions.'
        },

        'locale-sepa_debit-mandate': {
            type: String,
            default: 'By providing your payment information and confirming this payment, you authorise (A) {{company}} and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.'
        },

        'locale-au_becs_debit-mandate': {
            type: String,
            default: 'By providing your bank account details and confirming this payment, you agree to this Direct Debit Request and the Direct Debit Request service agreement, Direct Debit Request service agreement, and authorise Stripe Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User ID number 507156 (“Stripe”) to debit your account through the Bulk Electronic Clearing System (BECS) on behalf of (the “Merchant”) for any amounts separately communicated to you by the Merchant. You certify that you are either an account holder or an authorised signatory on the account listed above.'
        },

        'locale-company': {
            type: String,
            default: 'Us'
        }
    },

    methods: {
        /**
         * Select payment method
         * @param {string} method - The payment method to select
         */
        select: function(method) {
            this._select(method);
        }
    },

    render: function(node) {
        this._render();
    },

    update: function(props) {
        if (this.props.display != props.display || !dmx.equal(this.props['payment-methods'], props['payment-methods'])) {
            this._render();
        } else if (this.props.selected != props.selected) {
            this._select(this.props.selected);
        }
    },

    _bootstrapVersion: function() {
        return window.bootstrap && bootstrap.Alert ? parseInt(bootstrap.Alert.VERSION) : 0;
    },

    _select: function(method) {
        if (this.props['payment-methods'].includes(method)) {
            switch (this.props.display) {
                case 'pills':
                case 'tabs':
                    break;
                case 'buttons':
                    var radio = this.$node.querySelector('input[value=' + method + ']');
                    if (radio) radio.click()
                    break;
                default:
                    var select = this.$node.querySelector('select');
                    if (select) select.value = method;
                    break;
            }
        }
    },

    _render: function() {
        var self = this;
        var template = '';
        var bootstrap = this._bootstrapVersion();

        if (typeof this.props['payment-methods'] == 'string') {
            this.props['payment-methods'] = this.props['payment-methods'].split(',');
        }

        if (!Array.isArray(this.props['payment-methods'])) {
            return;
        }
        
        switch (this.props.display) {
            case 'pills':
            case 'tabs':
                template += '<ul class="nav nav-' + this.props.display + '">';
                this.props['payment-methods'].forEach(function(method) {
                    template += '<li class="nav-item">';
                    if (bootstrap == 4) {
                        template += '<a value="' + method + '" class="nav-link' + (method == self.props.selected ? ' active' : '') + '" data-toggle="tab" data-target="#payment-method-' + method + '">' + self.props['locale-' + method] + '</a>';
                    } else {
                        template += '<button value="' + method + '" class="nav-link' + (method == self.props.selected ? ' active' : '') + '" data-bs-toggle="tab" data-bs-target="#payment-method-' + method + '">' + self.props['locale-' + method] + '</button>';
                    }
                    template += '</li>';
                });
                template += '</ul>';
                break;

            case 'buttons':
                if (bootstrap == 4) {
                    template += '<div class="btn-group btn-group-toggle" data-toggle="buttons" role="group">';
                    this.props['payment-methods'].forEach(function(method) {
                        template += '<label class="btn btn-secondary' + (method == self.props.selected ? ' active' : '') + '">';
                        template += '<input type="radio" name="paymentmethod" value="' + method + '"' + (method == self.props.selected ? ' checked' : '') + '>';
                        template += self.props['locale-' + method];
                        template += '</label>'
                    });
                    template += '</div>';
                } else {
                    template += '<div class="btn-group" role="group">';
                    this.props['payment-methods'].forEach(function(method) {
                        template += '<input type="radio" class="btn-check" name="paymentmethod" value="' + method + '" id="paymentmethod_' + method + '" autocomplete="off"' + (method == self.props.selected ? ' checked' : '') + '>';
                        template += '<label class="btn btn-outline-primary" for="paymentmethod_' + method + '">' + self.props['locale-' + method] + '</label>';
                    });
                    template += '</div>';
                }
                break;
                
            default:
                if (bootstrap == 4) {
                    template += '<select class="form-control">';
                } else {
                    template += '<select class="form-select">';
                }
                this.props['payment-methods'].forEach(function(method) {
                    template += '<option value="' + method + '"' + (method == self.props.selected ? ' selected' : '') + '>' + self.props['locale-' + method] + '</option>';
                });
                template += '</select>';
                template += '</div>'
                break;
        }

        template += '<div class="tab-content my-3">';
        this.props['payment-methods'].forEach(function(method) {
            template += '<div id="payment-method-' + method + '" class="tab-pane' + (method == self.props.selected ? ' active' : '') + '">';
            template += '<div id="' + method + '-element"></div>';
            template += '<div id="' + method + '-error" class="invalid-feedback"></div>'
            template += '<div id="' + method + '-mandate"></div>';
            template += '</div>';
        });
        template += '</div>';

        this.$node.innerHTML = template;

        switch (this.props.display) {
            case 'pills':
            case 'tabs':
                if (bootstrap == 4) {
                    $('a[data-toggle="tab"]', this.$node).on('shown.bs.tab', function(event) {
                        self.set('selected', event.target.getAttribute('value'));
                    });
                } else {
                    var tabs = this.$node.querySelector('.nav');
                    tabs.addEventListener('shown.bs.tab', function(event) {
                        self.set('selected', event.target.getAttribute('value'));
                    });
                }
                break;
            case 'buttons':
                if (bootstrap == 4) {
                    var radios = this.$node.querySelectorAll('input');
                    for (var i = 0; i < radios.length; i++) {
                        radios[i].onchange = function(event) {
                            var selected = self.$node.querySelector('input:checked');
                            self.set('selected', selected.value);
                            self.$node.querySelector('.tab-content .active').classList.remove('active');
                            document.getElementById('payment-method-' + selected.value).classList.add('active');
                        };
                    }
                } else {
                    var radios = this.$node.querySelectorAll('input');
                    for (var i = 0; i < radios.length; i++) {
                        radios[i].onchange = function(event) {
                            var selected = self.$node.querySelector('input:checked');
                            self.set('selected', selected.value);
                            self.$node.querySelector('.active').classList.remove('active');
                            document.getElementById('payment-method-' + selected.value).classList.add('active');
                        };
                    }
                }
                break;
            default:
                var select = this.$node.querySelector('select');
                select.onchange = function(event) {
                    self.set('selected', select.value);
                    self.$node.querySelector('.active').classList.remove('active');
                    document.getElementById('payment-method-' + select.value).classList.add('active');
                };
                break;
        }

        this.props['payment-methods'].forEach(function(method) {
            var classes = dmx.stripe.theme.bootstrap.classes;
            var style = dmx.stripe.theme.bootstrap.style;

            switch (method) {
                case 'card':
                    var card = dmx.stripe.elements.getElement('card');
                    if (!card) {
                        card = dmx.stripe.elements.create('card', {
                            classes: classes,
                            style: style,
                            hidePostalCode: this.props['hide-postal-code'],
                            iconStyle: this.props['icon-style'],
                            hideIcon: this.props['hide-icon']
                        });
                        card.mount('#' + method + '-element');
                        card.on('change', function(event) {
                            if (!self.props['hide-errors']) {
                                var displayError = document.getElementById(method + '-error');
                                if (event.error) {
                                    displayError.textContent = event.error.message;
                                } else {
                                    displayError.textContent = '';
                                }
                            }
                        });
                    }
                    break;

                case 'au_becs_debit':
                    var auBankAccount = dmx.stripe.elements.getElement('auBankAccount');
                    if (!auBankAccount) {
                        auBankAccount = dmx.stripe.elements.create('auBankAccount', {
                            classes: classes,
                            style: style,
                            iconStyle: this.props['icon-style'],
                            hideIcon: this.props['hide-icon']
                        });
                        auBankAccount.mount('#' + method + '-element');
                        auBankAccount.on('change', function(event) {
                            if (!self.props['hide-errors']) {
                                var displayError = document.getElementById(method + '-error');
                                if (event.error) {
                                    displayError.textContent = event.error.message;
                                } else {
                                    displayError.textContent = '';
                                }
                            }
                        });
                    }
                    document.getElementById(method + '-mandate').textContent = this.props['locale-' + method + '-mandate'].replace('{{company}}', this.props['locale-company']);
                    document.getElementById(method + '-mandate').className = 'alert alert-info mt-3';
                    break;

                case 'sepa_debit':
                    var iban = dmx.stripe.elements.getElement('iban');
                    if (!iban) {
                        iban = dmx.stripe.elements.create('iban', {
                            classes: classes,
                            style: style,
                            supportedCountries: ['SEPA'],
                            iconStyle: this.props['icon-style'],
                            hideIcon: this.props['hide-icon']
                        });
                        iban.mount('#' + method + '-element');
                        iban.on('change', function(event) {
                            if (!self.props['hide-errors']) {
                                var displayError = document.getElementById(method + '-error');
                                if (event.error) {
                                    displayError.textContent = event.error.message;
                                } else {
                                    displayError.textContent = '';
                                }
                            }
                        });
                    }
                    document.getElementById(method + '-mandate').textContent = this.props['locale-' + method + '-mandate'].replace('{{company}}', this.props['locale-company']);
                    document.getElementById(method + '-mandate').className = 'alert alert-info mt-3';
                    break;

                case 'ideal':
                    var idealBank = dmx.stripe.elements.getElement('idealBank');
                    if (!idealBank) {
                        idealBank = dmx.stripe.elements.create('idealBank', {
                            classes: classes,
                            style: style,
                            hideIcon: this.props['hide-icon']
                        });
                        idealBank.mount('#' + method + '-element');
                        idealBank.on('change', function(event) {
                            if (!self.props['hide-errors']) {
                                var displayError = document.getElementById(method + '-error');
                                if (event.error) {
                                    displayError.textContent = event.error.message;
                                } else {
                                    displayError.textContent = '';
                                }
                            }
                        });
                    }
                    break;

                case 'fpx':
                    var fpxBank = dmx.stripe.elements.getElement('fpxBank');
                    if (!fpxBank) {
                        fpxBank = dmx.stripe.elements.create('fpxBank', {
                            classes: classes,
                            style: style,
                            accountHolderType: this.props['account-holder-type']
                        });
                        fpxBank.mount('#' + method + '-element');
                        fpxBank.on('change', function(event) {
                            if (!self.props['hide-errors']) {
                                var displayError = document.getElementById(method + '-error');
                                if (event.error) {
                                    displayError.textContent = event.error.message;
                                } else {
                                    displayError.textContent = '';
                                }
                            }
                        });
                    }
                    document.getElementById(method + '-mandate').textContent = this.props['locale-' + method + '-conditions'].replace('{{company}}', this.props['locale-company']);
                    document.getElementById(method + '-mandate').className = 'alert alert-info mt-3';
                    break;

                case 'p24':
                    var p24Bank = dmx.stripe.elements.getElement('p24Bank');
                    if (!p24Bank) {
                        p24Bank = dmx.stripe.elements.create('p24Bank', {
                            classes: classes,
                            style: style,
                            hideIcon: this.props['hide-icon']
                        });
                        p24Bank.mount('#' + method + '-element');
                        p24Bank.on('change', function(event) {
                            if (!self.props['hide-errors']) {
                                var displayError = document.getElementById(method + '-error');
                                if (event.error) {
                                    displayError.textContent = event.error.message;
                                } else {
                                    displayError.textContent = '';
                                }
                            }
                        });
                    }
                    break;

                case 'eps':
                    var epsBank = dmx.stripe.elements.getElement('epsBank');
                    if (!epsBank) {
                        epsBank = dmx.stripe.elements.create('epsBank', {
                            classes: classes,
                            style: style,
                            hideIcon: this.props['hide-icon']
                        });
                        epsBank.mount('#' + method + '-element');
                        epsBank.on('change', function(event) {
                            if (!self.props['hide-errors']) {
                                var displayError = document.getElementById(method + '-error');
                                if (event.error) {
                                    displayError.textContent = event.error.message;
                                } else {
                                    displayError.textContent = '';
                                }
                            }
                        });
                    }
                    break;
            }
        }, this);
    }

});