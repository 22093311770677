dmx.Component('stripe', {
    /**
     * @property {Object} initialData
     * @property {Object} initialData.data
     * @property {Object|string} initialData.lastError
     */
    initialData: {
        data: null,
        lastError: null
    },

    attributes: {
        'key': {
            type: String,
            default: null
        },

        'account': {
            type: String,
            default: null
        },

        'locale': {
            type: String,
            default: 'auto'
        },

        'line-items': {
            type: [String, Array],
            default: null
        },

        'mode': {
            type: String,
            default: 'payment',
            enum: ['payment', 'subscription']
        },

        'success-url': {
            type: String,
            default: null
        },
        
        'cancel-url': {
            type: String,
            default: null
        },

        'session-url': {
            type: String,
            default: null
        },

        'payment-url': {
            type: String,
            default: null
        },

        'setup-url': {
            type: String,
            default: null
        }
    },

    methods: {
        /**
         * Redirect to checkout.
         * @param {Object} data 
         * @returns 
         */
        checkout: function(data) {
            var self = this;

            if (this.props['session-url']) {
                var url = this.props['session-url'];

                if (window.WebviewProxy) { // Cordova webview proxy plugin
                    url = window.WebviewProxy.convertProxyUrl(url);
                }

                var xhr = new XMLHttpRequest();
                xhr.onload = function() {
                    if (xhr.status == 200) {
                        var data;

                        try {
                            data = JSON.parse(xhr.responseText);
                        } catch(e) {
                            self.error(e.message);
                            return;
                        }

                        if (!data.id) {
                            if (data.error) {
                                self.error(data.error);
                            } else {
                                self.error('Id is missing in session response.');
                            }
                            return;
                        }

                        dmx.stripe.instance.redirectToCheckout({ sessionId: data.id }).then(function(result) {
                            if (result.error) {
                                self.error(result.error);
                            }
                        }).catch(function(e) {
                            self.error(e.message);
                        });
                    } else {
                        self.error('An error occurred during the transaction.');
                    }
                };
                xhr.onerror = function() {
                    self.error('An error occurred during the transaction.');
                };
                xhr.withCredentials = true;
                xhr.open('POST', url);
                try {
                    xhr.setRequestHeader('Content-Type', 'application/json');
                    xhr.send(JSON.stringify(Object.assign({}, { lineItems: this.props['line-items'] || [] }, data)));
                } catch (e) {
                    self.error(e.message);
                }

                return;
            }

            var checkoutOptions = {
                lineItems: this.props['line-items'],
                mode: this.props['mode'],
                successUrl: this.props['success-url'],
                cancelUrl: this.props['cancel-url']
            };

            if (!checkoutOptions.successUrl) {
                this.error('successUrl is required.');
                return;
            }

            if (!checkoutOptions.cancelUrl) {
                this.error('cancelUrl is required.');
                return;
            }

            if (typeof checkoutOptions.lineItems == 'string') {
                checkoutOptions.lineItems = [checkoutOptions.lineItems];
            }

            if (!Array.isArray(checkoutOptions.lineItems)) {
                this.error('Invalid line items.');
                return;
            }

            checkoutOptions.lineItems = checkoutOptions.lineItems.map(function(item) {
                if (typeof item == 'string') {
                    return { price: item, quantity: 1 };
                }

                return { price: item.price || item.sku || item.plan, quantity: item.quantity || 1 };
            });

            checkoutOptions.successUrl = this.fixUrl(checkoutOptions.successUrl);
            checkoutOptions.cancelUrl = this.fixUrl(checkoutOptions.cancelUrl);

            dmx.stripe.instance.redirectToCheckout(checkoutOptions).then(function(result) {
                if (result.error) {
                    self.error(result.error);
                }
            });
        },

        /**
         * Post to server connect to create a payment intent
         * @param {Object} [data]
         * @returns 
         */
        createPayment: function(data) {
            if (this.props['payment-url']) {
                var self = this;
                var url = this.props['payment-url'];

                if (window.WebviewProxy) { // Cordova webview proxy plugin
                    url = window.WebviewProxy.convertProxyUrl(url);
                }

                var xhr = new XMLHttpRequest();
                xhr.onload = function() {
                    if (xhr.status == 200) {
                        var data;

                        try {
                            data = JSON.parse(xhr.responseText);
                            self.set('data', data);
                            if (data.client_secret) {
                                dmx.stripe.client_secret = data.client_secret;
                            }
                            self.dispatchEvent('done');
                            self.dispatchEvent('payment_created');
                        } catch(e) {
                            self.error(e.message);
                            return;
                        }
                    } else {
                        self.error('An error occurred during the transaction.');
                    }
                };
                xhr.onerror = function() {
                    self.error('An error occurred during the transaction.');
                };
                xhr.withCredentials = true;
                xhr.open('POST', url);
                try {
                    xhr.setRequestHeader('Content-Type', 'application/json');
                    xhr.send(JSON.stringify(Object.assign({ lineItems: this.props['line-items'] || [] }, data)));
                } catch (e) {
                    self.error(e.message);
                }

                return;
            }
        },

        /**
         * Post to server connect to create a setup intent
         * @param {Object} [data]
         * @returns 
         */
        createSetup: function(data) {
            if (this.props['setup-url']) {
                var self = this;
                var url = this.props['setup-url'];

                if (window.WebviewProxy) { // Cordova webview proxy plugin
                    url = window.WebviewProxy.convertProxyUrl(url);
                }

                var xhr = new XMLHttpRequest();
                xhr.onload = function() {
                    if (xhr.status == 200) {
                        var data;

                        try {
                            data = JSON.parse(xhr.responseText);
                            self.set('data', data);
                            if (data.client_secret) {
                                dmx.stripe.client_secret = data.client_secret;
                            }
                            self.dispatchEvent('done');
                            self.dispatchEvent('setup_created');
                        } catch(e) {
                            self.error(e.message);
                            return;
                        }
                    } else {
                        self.error('An error occurred during the transaction.');
                    }
                };
                xhr.onerror = function() {
                    self.error('An error occurred during the transaction.');
                };
                xhr.withCredentials = true;
                xhr.open('POST', url);
                try {
                    xhr.setRequestHeader('Content-Type', 'application/json');
                    xhr.send(JSON.stringify(Object.assign({ lineItems: this.props['line-items'] || [] }, data)));
                } catch (e) {
                    self.error(e.message);
                }

                return;
            }
        }
    },

    events: {
        done: Event,
        error: Event,
        ready: Event,
        payment_created: Event,
        setup_created: Event
    },

    /**
     * Render
     * @private
     * @param {HTMLElement} node 
     */
    render: function(node) {
        if (this.props.key) {
            this.setup();
        }
    },

    /**
     * Update
     * @private
     * @param {Object} props 
     */
    update: function(props) {
        if (!props.key && this.props.key) {
            this.setup();
        }
    },

    /**
     * Setup stripe
     * @private
     */
    setup: function() {
        try {
            console.log('Stripe Setup');
            dmx.stripe.locale = this.props.locale;
            if (this.props.account) {
                dmx.stripe.instance = Stripe(this.props.key, { stripeAccount: this.props.account, locale: this.props.locale });
            } else {
                dmx.stripe.instance = Stripe(this.props.key, { locale: this.props.locale });
            }
            dmx.stripe.elements = dmx.stripe.instance.elements({ locale: this.props.locale });
            if (dmx.stripe.wait.length) {
                dmx.stripe.wait.forEach(function(cb) {
                    cb(dmx.stripe.instance);
                });
                dmx.stripe.wait = [];
            }
            this.dispatchEvent('ready');
            console.log('Stripe Ready');
        } catch (err) {
            console.error(err);
        }
    },

    /**
     * Create a full url path.
     * @private
     * @param {string} url
     */
    fixUrl: function(url) {
        var link = document.createElement('a');
        link.setAttribute('href', url);
        return link.href;
    },

    /**
     * Handle error.
     * @param {string} msg 
     */
    error: function(msg) {
        console.error(msg);
        this.set('lastError', msg);
        this.dispatchEvent('error');
    }

});