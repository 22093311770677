dmx.Actions({

    'stripe.redirectToCheckout': function(options) {
        if (!options) options = {};
        if (!options.locale) options.locale = dmx.stripe.locale;
        return dmx.stripe.instance.redirectToCheckout(options);
    },

    'stripe.confirmCardPayment': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmCardPayment(options.clientSecret, options);
    },

    'stripe.confirmAlipayPayment': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmAlipayPayment(options.clientSecret, options);
    },

    'stripe.confirmAuBecsDebitPayment': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmAuBecsDebitPayment(options.clientSecret, options);
    },

    'stripe.confirmBancontactPayment': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmBancontactPayment(options.clientSecret, options);
    },

    'stripe.confirmEpsPayment': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmEpsPayment(options.clientSecret, options);
    },

    'stripe.confirmFpxPayment': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmFpxPayment(options.clientSecret, options);
    },

    'stripe.confirmGiropayPayment': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmGiropayPayment(options.clientSecret, options);
    },

    'stripe.confirmGrabPayPayment': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmGrabPayPayment(options.clientSecret, options);
    },

    'stripe.confirmIdealPayment': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmIdealPayment(options.clientSecret, options);
    },

    'stripe.confirmOxxoPayment': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmOxxoPayment(options.clientSecret, options);
    },

    'stripe.confirmP24Payment': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmP24Payment(options.clientSecret, options);
    },

    'stripe.confirmSepaDebitPayment': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmSepaDebitPayment(options.clientSecret, options);
    },

    'stripe.confirmSofortPayment': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmSofortPayment(options.clientSecret, options);
    },

    'stripe.handleCardAction': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.handleCardAction(options.clientSecret);
    },

    'stripe.retrievePaymentIntent': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.retrievePaymentIntent(options.clientSecret);
    },

    'stripe.confirmCardSetup': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmCardSetup(options.clientSecret, options);
    },

    'stripe.confirmAuBecsDebitSetup': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmAuBecsDebitSetup(options.clientSecret, options);
    },

    'stripe.confirmBacsDebitSetup': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmBacsDebitSetup(options.clientSecret, options);
    },

    'stripe.confirmBancontactSetup': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmBancontactSetup(options.clientSecret, options);
    },

    'stripe.confirmIdealSetup': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmIdealSetup(options.clientSecret, options);
    },

    'stripe.confirmSepaDebitSetup': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmSepaDebitSetup(options.clientSecret, options);
    },

    'stripe.confirmSofortSetup': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.confirmSofortSetup(options.clientSecret, options);
    },

    'stripe.retrieveSetupIntent': function(options) {
        if (!options) options = {};
        if (!options.clientSecret) throw Error('clientSecret is required');
        return dmx.stripe.instance.retrieveSetupIntent(options.clientSecret);
    },

    'stripe.createPaymentMethod': function(options) {
        if (!options) options = {};
        if (!options.type) throw Error('type is required');
        return dmx.stripe.instance.createPaymentMethod(options);
    },

    'stripe.createSource': function(options) {
        if (!options) options = {};
        if (!options.type) throw Error('type is required');
        return dmx.stripe.instance.createSource(options);
    },

    'stripe.retrieveSource': function(options) {
        if (!options) options = {};
        if (!options.id) throw Error('id is required');
        if (!options.client_secret) throw Error('client_secret is required');
        return dmx.stripe.instance.retrieveSource(options);
    },

});