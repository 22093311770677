/**
 * @typedef StripeIbanData
 * @type {Object}
 * @property {boolean} complete
 * @property {string} country
 * @property {string} bankName
 * @property {boolean} empty
 * @property {StripeError} error
 * @property {StripeSource} source
 * @property {StripePaymentMethod} paymentMethod
 * @property {StripePaymentIntent} paymentIntent
 * @property {StripeSetupIntent} setupIntent
 */

/**
 * @typedef StripeSourceResponse
 * @type {Object}
 * @property {StripeError} error
 * @property {StripeSource} source
 */

/**
 * @typedef StripePaymentMethodResponse
 * @type {Object}
 * @property {StripeError} error
 * @property {StripePaymentMethod} paymentMethod
 */

/**
 * @typedef StripePaymentIntentResponse
 * @type {Object}
 * @property {StripeError} error
 * @property {StripePaymentIntent} paymentIntent
 */

/**
 * @typedef StripeSetupIntentResponse
 * @type {Object}
 * @property {StripeError} error
 * @property {StripeSetupIntent} setupIntent
 */

/**
 * @typedef StripeError
 * @type {Object}
 * @property {string} type
 * @property {string} code
 * @property {string} decline_code
 * @property {string} message
 * @property {string} param
 * @property {StripePaymentIntent} payment_intent
 * @property {string} charge
 * @property {StripePaymentMethod} payment_method
 * @property {string} payment_method_type
 * @property {StripeSetupIntent} setup_intent
 * @property {StripeSource} source
 */

/**
 * @typedef StripeSource
 * @type {Object}
 * @property {string} id
 * @property {number} amount
 * @property {string} currency
 * @property {string} customer
 * @property {Object} metadata
 * @property {Object} owner
 * @property {Object} redirect
 * @property {string} statement_descriptor
 * @property {string} status
 * @property {string} type
 * @property {string} object
 * @property {string} client_secret
 * @property {Object} code_verification
 * @property {number} created
 * @property {string} flow
 * @property {boolean} livemode
 * @property {Object} source_order
 * @property {string} usage
 */

/**
 * @typedef StripePaymentMethod
 * @type {Object}
 * @property {string} id
 * @property {Object} billing_details
 * @property {string} customer
 * @property {Object} metadata
 * @property {string} type
 * @property {string} object
 * @property {Object} afterpay_clearpay
 * @property {Object} alipay
 * @property {Object} au_becs_debit
 * @property {Object} bacs_debit
 * @property {Object} bancontact
 * @property {Object} card
 * @property {Object} card_present
 * @property {number} created
 * @property {Object} eps
 * @property {Object} fpx
 * @property {Object} giropay
 * @property {Object} grabpay
 * @property {Object} ideal
 * @property {Object} interac_present
 * @property {boolean} livemode
 * @property {Object} oxxo
 * @property {Object} p24
 * @property {Object} sepa_debit
 * @property {Object} sofort
 */

/**
 * @typedef StripePaymentIntent
 * @type {Object}
 * @property {string} id
 * @property {number} amount
 * @property {string} client_secret
 * @property {string} currency
 * @property {string} description
 * @property {StripeError} last_payment_error
 * @property {StripePaymentMethod} last_payment_error.payment_method
 * @property {Object} next_action
 * @property {Object} next_action.redirect_to_url
 * @property {string} next_action.redirect_to_url.return_url
 * @property {string} next_action.redirect_to_url.url
 * @property {string} next_action.type
 * @property {Object} next_action.use_stripe_sdk
 * @property {string} payment_method
 * @property {string[]} payment_method_types
 * @property {string} receipt_email
 * @property {string} setup_future_usage
 * @property {Object} shipping
 * @property {Object} shipping.address
 * @property {string} shipping.address.city
 * @property {string} shipping.address.country
 * @property {string} shipping.address.line1
 * @property {string} shipping.address.line2
 * @property {string} shipping.address.postal_code
 * @property {string} shipping.address.state
 * @property {string} shipping.carrier
 * @property {string} shipping.name
 * @property {string} shipping.phone
 * @property {string} shipping.tracking_number
 * @property {string} status
 * @property {string} object
 * @property {number} canceled_at
 * @property {string} cancellation_reason
 * @property {string} capture_method
 * @property {string} confirmation_method
 * @property {number} created
 * @property {boolean} livemode
 */

/**
 * @typedef StripeSetupIntent
 * @type {Object}
 * @property {string} id
 * @property {string} client_secret
 * @property {string} description
 * @property {StripeError} last_setup_error
 * @property {Object} next_action
 * @property {Object} next_action.redirect_to_url
 * @property {string} next_action.redirect_to_url.return_url
 * @property {string} next_action.redirect_to_url.url
 * @property {string} next_action.type
 * @property {Object} next_action.use_stripe_sdk
 * @property {string} payment_method
 * @property {string[]} payment_method_types
 * @property {string} status
 * @property {string} usage
 * @property {string} object
 * @property {string} cancellation_reason
 * @property {number} created
 * @property {boolean} livemode
 */

dmx.Component('stripe-iban', {
    /**
     * @property {StripeIbanData} initialData
     */
    initialData: {
        complete: false,
        country: '',
        bankName: '',
        empty: true,
        error: undefined,
        source: undefined,
        paymentMethod: undefined,
        paymentIntent: undefined,
        setupIntent: undefined
    },

    attributes: {
        'supported-countries': {
            type: Array,
            default: ['SEPA']
        },

        'placeholder-country': {
            type: String,
            default: ''
        },

        'icon-style': {
            type: String,
            default: 'default',
            enum: ['default', 'solid']
        },

        'hide-icon': {
            type: Boolean,
            default: false
        },

        'disabled': {
            type: Boolean,
            default: false
        },

        'hide-errors': {
            type: Boolean,
            default: false
        },

        'accountholder-name': {
            type: String,
            default: ''
        }
    },

    methods: {
        /**
         * Blurs the Element.
         */
        blur: function() {
            if (this.element) {
                this.element.blur();
            }
        },

        /**
         * Clears the value(s) of the Element.
         */
        clear: function() {
            if (this.element) {
                this.element.clear();
            }
        },

        /**
         * Focuses the Element.
         */
        focus: function() {
            if (this.element) {
                this.element.focus();
            }
        },

        /**
         * Convert information collected by the card element into a Source object that you safely pass to your server to use in an API call.
         * @param {Object} [data]
         * @returns {Promise<StripeSourceResponse>}
         */
        createSource: function(data) {
            if (this.element) {
                var self = this;
                return dmx.stripe.instance.createSource(this.element, data).then(function(result) {
                    if (result.error) {
                        self.set('error', result.error);
                        self.dispatchEvent('error');
                    } else {
                        self.set('source', result.source);
                        self.dispatchEvent('source_created');
                    }
                    return result;
                });
            }
        },

        /**
         * Convert information collected by the card element into a PaymentMethod object that you safely pass to your server to use in an API call.
         * @returns {Promise<StripePaymentMethodResponse>}
         */
        createPaymentMethod: function() {
            if (this.element) {
                var self = this;
                return dmx.stripe.instance.createPaymentMethod({
                    type: 'sepa_debit',
                    sepa_debit: this.element
                }).then(function(result) {
                    if (result.error) {
                        self.set('error', result.error);
                        self.dispatchEvent('error');
                    } else {
                        self.set('paymentMethod', result.paymentMethod);
                        self.dispatchEvent('payment_method_created');
                    }
                    return result;
                });
            }
        },

        /**
         * When called, it will confirm the PaymentIntent with data you provide and carry out 3DS or other next actions if they are required.
         * 
         * If you are using Dynamic 3D Secure, stripe.confirmCardPayment will trigger your Radar rules to execute and may open a dialog for your
         * customer to authenticate their payment.
         * 
         * When you confirm a PaymentIntent, it needs to have an attached PaymentMethod. In addition to
         * confirming the PaymentIntent, this method can automatically create and attach a new PaymentMethod for you. It can also be called with
         * an existing PaymentMethod, or if you have already attached a PaymentMethod you can call this method without needing to provide any
         * additional data. These use cases are detailed in the sections that follow.
         * @param {string} clientSecret
         * @returns {Promise<StripePaymentIntentResponse>}
         */
        confirmPayment: function(clientSecret) {
            if (this.element) {
                var self = this;
                return dmx.stripe.instance.confirmSepaDebitPayment(clientSecret || dmx.stripe.client_secret, {
                    payment_method: {
                        sepa_debit: this.element,
                        billing_details: {
                            name: this.props['accountholder-name']
                        }
                    }
                }).then(function(result) {
                    if (result.error) {
                        self.set('error', result.error);
                        self.dispatchEvent('error');
                    } else {
                        self.set('paymentIntent', result.paymentIntent);
                        self.dispatchEvent('payment_confirmed');
                    }
                    return result;
                });
            }
        },

        /**
         * When called, it will confirm the SetupIntent with data you provide and carry out 3DS or other next actions if they are required.
         * When you confirm a SetupIntent, it needs to have an attached PaymentMethod. In addition to confirming the SetupIntent, this method
         * can automatically create and attach a new PaymentMethod for you. It can also be called with an existing PaymentMethod, or if you 
         * have already attached a PaymentMethod you can call this method without needing to provide any additional data. These use cases are 
         * detailed in the sections that follow.
         * @param {string} clientSecret
         * @returns {Promise<StripeSetupIntentResponse>}
         */
        confirmSetup: function(clientSecret) {
            if (this.element) {
                var self = this;
                return dmx.stripe.instance.confirmSepaDebitSetup(clientSecret || dmx.stripe.client_secret, {
                    payment_method: {
                        sepa_debit: this.element,
                        billing_details: {
                            name: this.props['accountholder-name']
                        }
                    }
                }).then(function(result) {
                    if (result.error) {
                        self.set('error', result.error);
                        self.dispatchEvent('error');
                    } else {
                        self.set('setupIntent', result.setupIntent);
                        self.dispatchEvent('setup_confirmed');
                    }
                    return result;
                });
            }
        }
    },

    events: {
        change: Event,
        ready: Event,
        focus: Event,
        blur: Event,
        escape: Event,
        error: Event,
        source_created: Event,
        payment_method_created: Event,
        payment_confirmed: Event,
        setup_confirmed: Event
    },

    onchange: function(event) {
        this.set(event);

        if (!this.props['hide-errors']) {
            this.errorElement.textContent = event.error ? event.error.message : '';
        }
    },

    render: function(node) {
        var self = this;
        dmx.stripe.ready(function(stripe) {
            var theme = dmx.stripe.theme[window.bootstrap ? 'bootstrap' : 'default'];

            self.element = dmx.stripe.elements.create('iban', {
                supportedCountries: self.props['supported-countries'],
                placeholderCountry: self.props['placeholder-country'],
                iconStyle: self.props['icon-style'],
                hideIcon: self.props['hide-icon'],
                disabled: self.props['disabled'],
                classes: theme.classes,
                style: theme.style
            });

            self.element.mount(node);

            self.errorElement = document.createElement('div');
            self.errorElement.className = theme.error.class;

            node.insertAdjacentElement('afterend', self.errorElement);

            self.element.on('change', self.onchange.bind(self));
            self.element.on('change', self.dispatchEvent.bind(self, 'change'));
            self.element.on('ready', self.dispatchEvent.bind(self, 'ready'));
            self.element.on('focus', self.dispatchEvent.bind(self, 'focus'));
            self.element.on('blur', self.dispatchEvent.bind(self, 'blur'));
            self.element.on('escape', self.dispatchEvent.bind(self, 'escape'));
        });
    },

    update: function(props) {
        if (this.element) {
            if (this.props['supported-countries'] != props['supported-countries']) {
                this.element.update({ supportedCountries: this.props['supported-countries'] });
            }

            if (this.props['placeholder-country'] != props['placeholder-country']) {
                this.element.update({ placeholderCountry: this.props['placeholder-country'] });
            }

            if (this.props['icon-style'] != props['icon-style']) {
                this.element.update({ iconStyle: this.props['icon-style'] });
            }

            if (this.props['hide-icon'] != props['hide-icon']) {
                this.element.update({ hideIcon: this.props['hide-icon'] });
            }

            if (this.props['disabled'] != props['disabled']) {
                this.element.update({ disabled: this.props['disabled'] });
            }
        }
    },

    destroy: function() {
        if (this.element) {
            this.element.destroy();
        }
    }

});