dmx.stripe = {
    wait: [],
    locale: 'auto',
    instance: null,
    elements: null,
    ready: function(cb) {
        if (dmx.stripe.instance) {
            cb(dmx.stripe.instance);
        } else {
            dmx.stripe.wait.push(cb);
        }
    },
    theme: {
        default: {
            classes: {},
            style: {},
            error: {
                class: 'dmxValidator-invalid'
            }
        },
        bootstrap: {
            classes: {
                base: 'form-control',
                invalid: 'is-invalid'
            },
            style: {
                base: {
                    fontSize: '16px',
                    lineHeight: '26px'
                }
            },
            error: {
                class: 'invalid-feedback'
            }
        }
    }
};